<template>
  <div id="siteContainer">
    <div id="mainHeading">
      <div class="header">
        <h1><router-link to="/media">Media</router-link></h1>
        <breadcrumbs></breadcrumbs>
      </div>
    </div>

    <router-view id="mainColumn1"></router-view>

    <div id="mainColumn2">
      <div id="leftnavContent">
        <media-menu></media-menu>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script lang="ts">
  import MediaMenu from '@/components/MediaMenu.vue';

  export default {
    data: function() {
      return {}
    },
    components: {
      MediaMenu,
    }
  }
</script>
