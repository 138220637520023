
import SecondaryNavigation from "@/components/SecondaryNavigation.vue";

export default {
  data: function () {
    return {
      menuEntries: [
        {
          title: "Images", // section 141011
          image: require("@/assets/media-sections/images.png"),
          links: [
            {
              title: "Screenshots", // category 140034
              href: "/media/s/images/c/screenshots",
            },
            {
              title: "Concept Art", // category 140035
              href: "/media/s/images/c/concept-art",
            },
            {
              title: "Posters", // category 149972
              href: "/media/s/images/c/posters",
            },
          ],
        },
        {
          title: "Videos", // section 141010
          image: require("@/assets/media-sections/videos.png"),
          links: [
            {
              title: "Cinematic Trailers", // c 141100
              href: "/media/s/videos/c/trailers",
            },
            {
              title: "Info Videos", // c 272859
              href: "/media/s/videos/c/info",
            },
            {
              title: "In-Game Videos", // c 194313
              href: "/media/s/videos/c/in-game",
            },
            {
              title: "Behind the Scenes", // c 149650
              href: "/media/s/videos/c/behind-the-scenes",
            },
          ],
        },
        {
          title: "Downloads",
          image: require("@/assets/media-sections/downloads.png"),
          links: [{ title: "Wallpapers", href: "/media/section/141012/category/141103" }],
        },
        {
          title: "Press Area",
          image: require("@/assets/media-sections/press-area.png"),
          links: [
            { title: "Welcome", href: "/media/section/220158" },
            { title: "Press Releases", href: "/media/section/141010/category/141107" },
            { title: "Press Assets", href: "/media/section/141010/category/141106" },
          ],
        },
      ],
    };
  },
  components: {
    SecondaryNavigation,
  },
};
