
  import MediaMenu from '@/components/MediaMenu.vue';

  export default {
    data: function() {
      return {}
    },
    components: {
      MediaMenu,
    }
  }
